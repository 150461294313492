import { request } from '../request';

import { ObjProp } from '@/config/types';
import { TLogin } from '@/pages/auth/CaLogin';
import { forgetPasswordProps } from '@/pages/auth/CaPasswordForgot';
import { forgetPasswordResetParams } from '@/pages/auth/CaPasswordReset';

export const auth = {
  // 检查accessToken的合法性
  checkAccessToken: () => {
    return request({
      url: '/isLogin',
    });
  },

  // 请求注册邮件
  getLogonEmail: (data) => {
    return request({
      method: 'post',
      url: '/getRegister',
      data,
    });
  },

  // 根据key请求邮箱地址
  getEmailAddressByKey: (params: { key: string }) => {
    return request({
      url: '/checkMailUrl',
      params,
    });
  },

  // 忘记密码链接是否有效
  CheckForgetUrlKey: (params: { key: string }) => {
    return request({
      url: '/checkForgetUrl',
      params,
    });
  },

  // 请求毕业年份列表数据
  getGraduateYearList: () => {
    return request({
      url: '/graduationTime',
    });
  },

  // 请求学校列表数据
  getSchoolList: (params: { kana: string }) => {
    return request({
      url: '/searchUniversity',
      params,
    });
  },

  // 请求团体列表数据
  getAddressByPostcode: (params: { zip: string }) => {
    return request({
      url: '/searchAddressByzip',
      params,
    });
  },

  // 获取会員規約情報
  getMumberTerm: () => {
    return request({
      url: '/getTerms',
    });
  },

  // 请求团体列表数据
  getOrganizationList: () => {
    return request({
      url: '/organizationList',
    });
  },

  // 用户注册
  userRegister: (data: ObjProp) => {
    return request({
      method: 'post',
      url: '/register',
      data,
    });
  },

  // チェック電話番号
  checkMobile: (data) => {
    return request({
      method: 'post',
      url: '/checkMobile',
      data,
    });
  },

  // 用户登录
  userLogin: (data: TLogin) => {
    return request({
      method: 'post',
      url: '/login',
      data,
    });
  },

  // パスワードをお忘れの方
  userForgetPassword: (data: forgetPasswordProps) => {
    return request({
      method: 'post',
      url: '/forgetPassword',
      data,
    });
  },
  // パスワードリセット
  userForgetPasswordReset: (data: forgetPasswordResetParams) => {
    return request({
      method: 'put',
      url: '/forgetPasswordReset',
      data,
    });
  },
  checkEmail: (data) => {
    return request({
      method: 'post',
      url: '/checkEmail',
      data,
    });
  }

};

