import CaButton from "@/components/CaButton";
import { CaComponentProps } from "@/config/types";
import { CaretRightOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import useMediaQuery from "use-media-antd-query";
import routes from "../../config/routes";
import user from "../../data/user";
import LogoSvg from "../../public/imgs/logo.svg";
import MenuSvg from "../../public/imgs/public/menu.svg";
import { COMMONLIST, USERLIST } from "./configs";
import "./index.less";

type props = CaComponentProps & {
  getMenuStatus: Function;
};

const COMMONLISTAll = COMMONLIST;
const USERLISTAll = USERLIST;

const CaHeader: React.FC<props> = ({ getMenuStatus }) => {
  const navigate = useNavigate();
  const [HEADERLIST, setHEADERLIST] = useState(COMMONLIST);
  const [HEADERLIST1, setHEADERLIST1] = useState(USERLISTAll);
  const element = useRoutes(routes);
  const location = useLocation();
  const [typeHeader, setTypeHeader] = useState(0); // 0是正常PC头部 1为MyPage特殊头部
  const isLogin = user.isLogin();
  useEffect(() => {
    // console.log("更新");
    const isUserHeader = location.pathname.indexOf("MyPage");
    if (
      isUserHeader > -1 ||
      location.pathname.indexOf("myPage") > -1 ||
      location.pathname.indexOf("mypage") > -1 ||
      location.pathname.indexOf("my-page") > -1
    ) {
      setTypeHeader(1);
    } else {
      setTypeHeader(0);
    }
  }, [location.pathname]);
  const colSize = useMediaQuery();
  const isMobile =
    colSize === "xs" ||
    colSize === "sm" ||
    colSize === "md" ||
    colSize === "lg";
  const watchMenu = () => {
    getMenuStatus("xxxx");
  };
  const toPath = (link) => {
    navigate(link);
  };

  const backHome = () => {
    navigate("/");
  };

  const toMyPage = () => {
    navigate("/myPage");
  };

  const toLogin = () => {
    navigate("/auth/login");
  };

  const toLogon = () => {
    navigate("/auth/register");
  };

  const toLogout = () => {
    user.clearAccessToken();
    navigate("/auth/login");
  };

  return (
    <header
      className={classNames(
        "ca-public-layout-header",
        isMobile
          ? "ca-public-layout-header-phone"
          : "ca-public-layout-header-pc"
      )}
    >
      <div className="public-layout-11234-header-left" onClick={backHome}>
        <div className="public-layout-header-svg">
          <LogoSvg />
          <p>女子キャリ就活</p>
        </div>
      </div>
      {isMobile ? (
        <div className="public-layout-header-right">
          {/* TOPページ　かつ　ログインしてない */}
          {typeHeader === 0 && !isLogin && (
            <div onClick={toLogon} className="model-button">
              <p>無料で会員登録</p>
              <CaretRightOutlined />
            </div>
          )}

          <div onClick={watchMenu}>
            <MenuSvg />
          </div>
        </div>
      ) : (
        <div className="ca-public-layout-header-list-pc">
          <Row gutter={20} className="ca-public-layout-header-list-pc-list">
            {typeHeader === 0
              ? HEADERLIST.map((items, index) => (
                  <Col
                    key={index}
                    onClick={() => toPath(items.link)}
                    className={classNames(
                      "font-list-hover-style-pc",
                      typeHeader === 0
                        ? "font-apparat"
                        : "ca-header-font-jpFont"
                    )}
                  >
                    {items.title}
                  </Col>
                ))
              : HEADERLIST1.map((items, index) => (
                  <Col
                    key={index}
                    onClick={() => toPath(items.link)}
                    className={classNames(
                      "font-list-hover-style-pc",
                      typeHeader === 0
                        ? "font-apparat"
                        : "ca-header-font-jpFont"
                    )}
                  >
                    {items.title}
                  </Col>
                ))}
          </Row>
          {/* TOPの場合 */}
          {typeHeader === 0 ? (
            isLogin ? (
              // ログインしている場合
              <Row
                gutter={10}
                className="ca-public-layout-header-list-pc-button"
              >
                <Col>
                  <CaButton type="primary" size="large" clickHandle={toMyPage}>
                    マイページへ
                  </CaButton>
                </Col>
                <Col>
                  <CaButton size="large" clickHandle={toLogout}>
                    ログアウト
                  </CaButton>
                </Col>
              </Row>
            ) : (
              // ログインしていない場合
              <Row
                gutter={10}
                className="ca-public-layout-header-list-pc-button"
              >
                <Col>
                  <CaButton type="primary" size="large" clickHandle={toLogon}>
                    無料で会員登録
                  </CaButton>
                </Col>
                <Col>
                  <CaButton type="primary" size="large" clickHandle={toLogin}>
                    ログイン
                  </CaButton>
                </Col>
              </Row>
            )
          ) : (
            // MyPageの場合
            // ca-public-layout-header-list-pc-button
            <Row
              gutter={10}
              className="ca-public-layout-header-list-pc-button-typeHeader"
            >
              <Col>
                <CaButton type="primary" size="large" clickHandle={backHome}>
                  トップページに戻る
                </CaButton>
              </Col>
              <Col>
                <CaButton size="large" clickHandle={toLogout}>
                  ログアウト
                </CaButton>
              </Col>
            </Row>
          )}
        </div>
      )}
    </header>
  );
};

export default CaHeader;
